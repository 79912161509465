













































import { Component } from "vue-property-decorator";
import { ButtonCpt } from "@/core/components/common";
import AppVue from "@/AppVue.vue";
import { AzureFirewallRuleVm } from "@/core/webapi";
import { ApiService } from "@/core/services";
import AddFirewallRuleCpt from "./AddFirewallRuleCpt.vue";

@Component({
  components: {
    ButtonCpt,
    AddFirewallRuleCpt,
  },
})
export default class AzurePermissionsView extends AppVue {
  model = new AzureFirewallRuleVm();
  showDialog = false;

  async created() {
    await this.loadCurrentFirewallRules();
  }

  async onAddRuleDialogClose(result: boolean) {
    this.showDialog = false;

    if (result) {
      await this.loadCurrentFirewallRules();
    }
  }

  private async loadCurrentFirewallRules() {
    const response = await ApiService.azurePermissions().getCurrentFirewallRules();
    this.model = response.data;
  }
}
