













































import { Component, Prop, Watch } from "vue-property-decorator";
import { BaseFormComponent, ButtonCpt, InputTextareaCpt } from "@/core/components";
import { ApiService, NotificationProvider } from "@/core/services";
import { AzureFirewallAddRule } from "@/core/webapi";

@Component({
  components: {
    ButtonCpt,
    InputTextareaCpt,
  },
})
export default class AddFirewallRuleCpt extends BaseFormComponent {
  @Prop() showDialog?: boolean;

  show = false;
  model = new AzureFirewallAddRule();

  @Watch("showDialog")
  onShow() {
    this.show = this.showDialog;
  }

  async onSubmitForm(): Promise<void> {
    this.isLoading = true;
    const issucess = await ApiService.azurePermissions()
      .addFirewallRule(this.model)
      .then(
        () => true,

        error => {
          if (error.response && error.response.data) {
            this.model.modelState = error.response.data.modelState;
          }

          this.$forceUpdate();

          return false;
        },
      );

    if (issucess) {
      this.$eventHub.$emit("FIREWALL_RULE_ADDED");
      NotificationProvider.success("Firewall rule sucessfully added.");
      this.close(true);
    }
    this.isLoading = false;
  }

  close(result: boolean) {
    this.show = false;
    this.model = new AzureFirewallAddRule();
    this.$emit("closed", result);
  }

  created() {
    this.show = this.showDialog;
  }
}
